import { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { ArrowBackIosNew, ArrowForwardIos, Folder, InsertDriveFile } from '@mui/icons-material'
import Email from './components/email'
import classNames from 'classnames'
import { addDays, isBefore } from 'date-fns'
import { Badge, Box } from '@mui/material';
import './affaireEmails.scss'
import Checkbox from '@mui/material/Checkbox';
import MenuEmail from './components/menu'

// const statusToColor = {
//   WAITING: 'warning',
//   IN_PROGRESS: 'info',
//   VALIDATED: 'success',
//   CONFIRMED: 'success',
//   ARCHIVED: 'error'
// }

// const statusToText = {
//   WAITING: 'En attente',
//   IN_PROGRESS: 'En cours',
//   VALIDATED: 'Validé',
//   CONFIRMED: 'Confirmé',
//   ARCHIVED: 'Archivé'
// }

const AffaireEmails = ({ affaireId, affairedata, docId }) => {
  const [selectedFile, setSelectedFile] = useState()

  const [currentPath, setCurrentPath] = useState([])
  const currentFolderId = useMemo(() => currentPath[currentPath.length - 1]?.id || undefined, [currentPath])
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const isRoot = !currentPath.length;

  const openFolder = (file) => {
    setCurrentPath(prev => [...prev, file])
  }

  const backFolder = (fileId) => {
    setCurrentPath(prev => fileId ? prev.slice(0, prev.findIndex(file => file.id === fileId) - prev.length + 1) : [])
  }

  const prevFolder = () => {
    setCurrentPath(prev => prev.slice(0, -1))
  }

  const [selectedEmails, setSelectedEmails] = useState([]);
  const [files, setFiles] = useState([])
  const sortedFiles = useMemo(() => files.sort((a, b) => a.folder === b.folder ? a.name < b.name ? -1 : 1 : a.folder ? -1 : 1), [files])

  const handleEmailChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedEmails(prev => [...prev, value])
    } else {
      setSelectedEmails(prev => prev.filter(element => element != value))
    }
  }

  const handleDelete = () => {
    const isConfirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ce(s) fichier(s) ?"
    );
    if (isConfirmed) {
      const param = selectedEmails.join("&files=");
      axios
        .delete(`emails/?files=${param}`)
        .then((res) => {
          setFiles(files.filter(file => !selectedEmails.includes(file._id)));
          setSelectedEmails([]);
        })
        .catch((error) => {
          console.error("Erreur lors de la suppression du fichier", error);
        });
    } else {
      console.log("Suppression annulée");
    }
  }

  const addFile = (newFile) => {
    setFiles(prev => [...prev, newFile])
  }

  const handleFileUpdate = (updatedFile) => {
    // Met à jour le fichier sélectionné si c'est celui qui a été mis à jour
    if (selectedFile && updatedFile._id === selectedFile._id) {
      setSelectedFile(updatedFile);
    }

    setUpdateTrigger((current) => current + 1);  // Cela déclenche le re-fetch
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(currentFolderId ? `/emails/${currentFolderId}/children` : `/affaires/${affaireId}/emails`)
        setFiles(res.data)
      } catch (error) {
        console.error('Erreur lors de la récupération des documents', error)
      }
    })()
  }, [currentPath, updateTrigger])

  return (
    <div className="affaireDocuments">
      <div className="header">
        <div className="path">
          <div key='path-origin' onClick={currentPath.length ? backFolder : undefined} className={classNames('item select-none', currentPath.length ? 'clickable' : '')}>
            Affaire {affairedata.nomAffaire}
            {currentPath.length ? <ArrowForwardIos /> : null}
          </div>
          {currentPath.map((file, fileIndex) => <div key={`path-${file.id}`} onClick={currentPath.length !== fileIndex + 1 ? () => backFolder(file.id) : undefined} className={classNames('item select-none', currentPath.length ? 'clickable' : '')}>
            <span>{file.name}</span>
            {currentPath.length !== fileIndex + 1 ? <ArrowForwardIos /> : null}
          </div>)}
        </div>
        <div className="actions">
          <Email />
        </div>
      </div>

      <Box component="section" sx={{ p: 2, mb: 2, border: '1px dashed grey' }}>
        Tous les emails transférés ou envoyés à l'adresse <span className="email-service" onClick={() => { navigator.clipboard.writeText(`${affairedata._id}@attachments.vision-team-build.com`) }}>
          {affairedata._id}@attachments.vision-team-build.com
        </span> seront enregistrés ici. <button onClick={() => { navigator.clipboard.writeText(`${affairedata._id}@attachments.vision-team-build.com`) }} style={{ float: "right" }}>Copier l'adresse email.</button>
      </Box>


      {isRoot &&
        <Box component="section" sx={{ p: 1, mb: 2 }}>
          <MenuEmail handleDelete={handleDelete} />
        </Box>
      }
      <div className="content" key={`folder-${currentFolderId || 'origin'}`}>
        {!isRoot
          ? <a onClick={prevFolder} href={undefined} target={undefined}>
            <div className='item select-none back' >
              <ArrowBackIosNew sx={{ fontSize: 30 }} />
            </div>
          </a>
          : null
        }
        {sortedFiles
          .map(file => <div key={file.id} >

            {isRoot &&
              <Checkbox
                style={{ marginRight: "1em" }}
                color="default"
                value={file.id}
                onChange={handleEmailChange}
              />
            }
            <Badge
              onClick={file.folder ? () => openFolder(file) : () => setSelectedFile(file)}
              badgeContent={file.comments.length}
              color="error"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{ ".MuiBadge-badge": { right: -3, top: 13, border: `2px solid`, borderColor: `background.paper`, padding: '0 4px' } }}
            >
              <div
                className={classNames('item select-none',
                  file.expirationDate && isBefore(new Date(file.expirationDate), new Date())
                    ? 'expired'
                    : file.expirationDate && isBefore(new Date(file.expirationDate), addDays(new Date(), 7))
                      ? 'expiringSoon'
                      : ''
                )}
              >
                {file.folder ? <Folder sx={{ fontSize: 60 }} /> : file.type.includes('image') ? <img src={file.url} /> : <InsertDriveFile sx={{ fontSize: 60 }} />}
                <div className="name">{file.name}</div>
              </div>
            </Badge>
          </div>)}
      </div>
      <Email open={!!selectedFile} onClose={() => setSelectedFile(undefined)} file={selectedFile} removeFile={(fileId) => setFiles(prev => prev.filter(file => file._id !== fileId))} updateFile={handleFileUpdate} />
    </div>
  )
}

export default AffaireEmails