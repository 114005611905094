import { useContext, useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
// import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from "@mui/material/DialogTitle";
import Close from "@mui/icons-material/Close";
import Send from "@mui/icons-material/Send";
import { format, isBefore } from "date-fns";

import "./email.scss";
import classNames from "classnames";
import { AuthContext } from "../../../../context/AuthContext";
import axios from "axios";

const roleToText = {
  ADMIN: "Admin",
  OUVRIER_TERRAIN: "Ouvrier terrain",
};

function Comment({ content, author, createdAt, me }) {
  return (
    <div className={classNames("comment", me ? "mine" : "")}>
      <div className="buble">{content}</div>
      <div className="infos">
        Par {author} le {format(new Date(createdAt), "dd/MM/yyyy")}
      </div>
    </div>
  );
}

const statusToText = {
  WAITING: "En attente",
  IN_PROGRESS: "En cours",
  VALIDATED: "Validé",
  CONFIRMED: "Confirmé",
  ARCHIVED: "Archivé",
};

const Email = ({ open, onClose, file, removeFile, updateFile }) => {
  const { utilisateur } = useContext(AuthContext);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);

  const createNotificationForComment = async (documentId, affaireId, name, userId) => {
    const notificationMessage = `Un nouveau commentaire a été ajouté au document ${name}.`;
    const notificationData = {
      utilisateurId: userId, // ID de l'utilisateur qui reçoit la notification
      message: notificationMessage,
      type: 'Nouveau Commentaire', // Assurez-vous que ce type est géré dans votre backend
      affaireId: affaireId, // ID de l'affaire
      documentId: documentId, // ID du document
      // Ajoutez d'autres champs comme requis par votre modèle de données
    };

    try {
      await axios.post('/notifications', notificationData);
      console.log("Notification créée avec succès.");
    } catch (error) {
      console.error("Erreur lors de la création de la notification", error);
    }
  };

  const submit = () => {
    axios
      .post(`files/${file._id}/comments`, { content: comment })
      .then((res) => {
        setComments((prev) => [res.data, ...prev]);
        setComment("");
        createNotificationForComment(file._id, file.affaire, file.name, utilisateur._id);
      });
  };

  useEffect(() => {
    if (file) {
      axios.get(`files/${file._id}/comments`).then((res) => {
        setComments(
          res.data.sort((a, b) => (isBefore(a.createdAt, b.className) ? 1 : -1))
        );
      });
    } else {
      setComments([]);
      setComment("");
    }
  }, [file]);

  const onDelete = () => {
    const isConfirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ce fichier ?"
    );
    if (isConfirmed) {
      axios
        .delete(`files/${file._id}`)
        .then((res) => {
          onClose();
          removeFile(file._id);
        })
        .catch((error) => {
          console.error("Erreur lors de la suppression du fichier", error);
        });
    } else {
      console.log("Suppression annulée");
    }
  };

  const handleUpdateOpen = () => {
    setIsUpdateDialogOpen(true); // Ouvre la boîte de dialogue de mise à jour
  };

  return (
    <>
      <Dialog open={open || false} onClose={onClose} fullWidth={true}>
        <Close
          onClick={onClose}
          style={{ cursor: "pointer", position: "absolute", right: 10, top: 10 }}
        />
        <DialogTitle>Email {file?.name}</DialogTitle>
        <DialogContent>
          {
            file?.url.includes('.html') ? <div className="info"><iframe src={file?.url}></iframe></div> :
              <div className="info">
                <div>
                  <span className="title">{"Date d'expiration :"}</span>{" "}
                  <span>
                    {file?.expirationDate
                      ? format(new Date(file?.expirationDate), "dd/MM/yyyy")
                      : ""}
                  </span>
                </div>
                <div>
                  <span className="title">Status :</span>{" "}
                  <span>{file?.status ? statusToText[file.status] : ""}</span>
                </div>
                <div>
                  <span className="title">Accès :</span>{" "}
                  <span>
                    {file?.access?.length
                      ? file.access.map((a) => roleToText[a]).join(", ")
                      : ""}
                  </span>
                </div>
                <div>
                  <span className="title">Créé le :</span>{" "}
                  <span>
                    {file?.createdAt
                      ? format(new Date(file?.createdAt), "dd/MM/yyyy")
                      : ""}
                  </span>
                </div>
              </div>
          }
          <Divider style={{ margin: "8px 0px" }} />
          <div className="comments">
            <div className="title">Commentaires</div>
            <div className="content">
              {comments.length ? (
                comments.map((comment) => (
                  <Comment
                    key={comment.id}
                    content={comment.content}
                    author={`${comment.author.prenom} ${comment.author.nom}`}
                    createdAt={comment.createdAt}
                    me={utilisateur._id === comment.author._id}
                  />
                ))
              ) : (
                <span className="noComment">💬 Pas encore de commentaire</span>
              )}
            </div>
            <FormControl
              fullWidth
              variant="standard"
              style={{ margin: "10px 0px" }}
            >
              <Input
                fullWidth
                id="comment-label"
                placeholder="Commentaire"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onKeyDown={(ev) => {
                  if (ev.key === "Enter") {
                    submit();
                    ev.preventDefault();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={submit} disabled={!comment}>
                      <Send />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 24px' }}>
          <Button color="error" onClick={onDelete}>Supprimer</Button>
          <a href={file?.url} target='_blank' rel="noreferrer">
            <Button variant="contained">Ouvrir le fichier</Button>
          </a>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Email
