import { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { ArrowBackIosNew, ArrowForwardIos, Folder, InsertDriveFile } from '@mui/icons-material'
// import Badge from '@mui/material/Badge'
import CreateFolder from './components/createFolder'
import CreateFile from './components/createFile'
import File from './components/file'
import classNames from 'classnames'
import { addDays, isBefore } from 'date-fns'
import { Badge } from '@mui/material';
import './affaireDocuments.scss'

// const statusToColor = {
//   WAITING: 'warning',
//   IN_PROGRESS: 'info',
//   VALIDATED: 'success',
//   CONFIRMED: 'success',
//   ARCHIVED: 'error'
// }

// const statusToText = {
//   WAITING: 'En attente',
//   IN_PROGRESS: 'En cours',
//   VALIDATED: 'Validé',
//   CONFIRMED: 'Confirmé',
//   ARCHIVED: 'Archivé'
// }

const AffairesDocument = ({ affaireId, affairedata , docId}) => {
  const [selectedFile, setSelectedFile] = useState()

  const [currentPath, setCurrentPath] = useState([])
  const currentFolderId = useMemo(() => currentPath[currentPath.length - 1]?.id || undefined, [currentPath])
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const openFolder = (file) => {
    setCurrentPath(prev => [...prev, file])
  }

  const backFolder = (fileId) => {
    setCurrentPath(prev => fileId ? prev.slice(0, prev.findIndex(file => file.id === fileId) - prev.length + 1) : [])
  }

  const prevFolder = () => {
    setCurrentPath(prev => prev.slice(0, -1))
  }

  const [files, setFiles] = useState([])
  const sortedFiles = useMemo(() => files.sort((a, b) => a.folder === b.folder ? a.name < b.name ? -1 : 1 : a.folder ? -1 : 1), [files])

  const addFile = (newFile) => {
    setFiles(prev => [...prev, newFile])
  }

  const handleFileUpdate = (updatedFile) => {
    // Met à jour le fichier sélectionné si c'est celui qui a été mis à jour
    if (selectedFile && updatedFile._id === selectedFile._id) {
      setSelectedFile(updatedFile);
    }
  
    setUpdateTrigger((current) => current + 1);  // Cela déclenche le re-fetch
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(currentFolderId ? `/files/${currentFolderId}/children` : `/affaires/${affaireId}/files`)
        setFiles(res.data)
      } catch (error) {
        console.error('Erreur lors de la récupération des documents', error)
      }
    })()
  }, [currentPath, updateTrigger])

  return (
    <div className="affaireDocuments">
      <div className="header">
        <div className="path">
          <div key='path-origin' onClick={currentPath.length ? backFolder : undefined} className={classNames('item select-none', currentPath.length ? 'clickable' : '')}>
            Affaire {affairedata.nomAffaire}
            {currentPath.length ? <ArrowForwardIos /> : null}
          </div>
          {currentPath.map((file, fileIndex) => <div key={`path-${file.id}`} onClick={currentPath.length !== fileIndex + 1 ? () => backFolder(file.id) : undefined} className={classNames('item select-none', currentPath.length ? 'clickable' : '')}>
            <span>{file.name}</span>
            {currentPath.length !== fileIndex + 1 ? <ArrowForwardIos /> : null}
          </div>)}
        </div>
        <div className="actions">
          <CreateFolder affaireId={affaireId} currentFolderId={currentFolderId} callback={addFile} />
          <CreateFile affaireId={affaireId} currentFolderId={currentFolderId} callback={addFile} />
          <File />
        </div>
      </div>
      <div className="content" key={`folder-${currentFolderId || 'origin'}`}>
        {currentPath.length
          ? <a onClick={prevFolder} href={undefined} target={undefined}>
              <div className='item select-none back' >
                <ArrowBackIosNew sx={{ fontSize: 30 }} />
              </div>
            </a>
          : null
          }
        {sortedFiles
        // <a key={file.id} onClick={file.folder ? () => openFolder(file) : undefined} href={file.folder ? undefined : file.url} target={file.folder ? undefined : '_blank'} rel="noreferrer">
          .map(file => <div key={file.id} onClick={file.folder ? () => openFolder(file) : () => setSelectedFile(file)}>
            <Badge
                badgeContent={file.comments.length}
                color="error"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{ ".MuiBadge-badge": { right: -3, top: 13, border: `2px solid`, borderColor: `background.paper`, padding: '0 4px' } }}
                >
            <div
              className={classNames('item select-none',
                file.expirationDate && isBefore(new Date(file.expirationDate), new Date())
                  ? 'expired'
                  : file.expirationDate && isBefore(new Date(file.expirationDate), addDays(new Date(), 7))
                    ? 'expiringSoon'
                    : ''
              )}
            >
              {/* {file.status ? <Badge style={{ fontSize: '8px', position: 'absolute', right: 0 }} color={statusToColor[file.status]}>{statusToText[file.status]}</Badge> : null} */}
              {file.folder ? <Folder sx={{ fontSize: 60 }} /> : file.type.includes('image') ? <img src={file.url} /> : <InsertDriveFile sx={{ fontSize: 60 }} />}
              <div className="name">{file.name}</div>
            </div>
            </Badge>
        </div>)}
      </div>
      <File open={!!selectedFile} onClose={() => setSelectedFile(undefined)} file={selectedFile} removeFile={(fileId) => setFiles(prev => prev.filter(file => file._id !== fileId))} updateFile={handleFileUpdate} />
    </div>
  )
}

export default AffairesDocument